html, body {
  background: #222;
}

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  font-family: 'Space Mono', monospace;
  font-size: 30px;
}

.soon {
  color: white;
}

